<template>
  <div class="QRCode">
    <div id="qrCode"></div>
    <h3 class="lobbyCode font-color--primary font-style--readable">{{gameId}}</h3>
  </div>
</template>

<script>
  import QRCodeStyling from 'qr-code-styling';  
  export default {

    name: 'QRCode',
    props: ['gameId'],
    components: {

    },
    data: () => {
      return {

      }
    },
    mounted() {
      const qrCode = new QRCodeStyling({
        width: 200,
        height: 200,
        data: this.thisURL+'?joining=true',
        dotsOptions: {
            "gradient":{"type":"linear","rotation":0,"colorStops":[{"offset":0,"color":document.documentElement.style.getPropertyValue('--app-color--primary__hex')},{"offset":1,"color":document.documentElement.style.getPropertyValue('--app-color--secondary__hex')}]},
            type: "rounded"
        },
        backgroundOptions: {
            color: "#e9ebee",
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
        }
      });
      qrCode.append(document.getElementById("qrCode"));
    },
    computed: {
      thisURL() {
        return `${process.env.VUE_APP_WEB_PATH}/join?code=${this.gameId}`;
      }
    }
  }
</script>

<style scoped lang="less">
.QRCode {
  .lobbyCode {
    font-size: 2em;
    margin-top: 10px;
  }
}
</style>
